import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-churches-and-cemeteries',
  templateUrl: './churches-and-cemeteries.component.html',
  styleUrls: ['./churches-and-cemeteries.component.css']
})
export class ChurchesAndCemeteriesComponent implements OnInit {

  constructor() { }
  isShow = false;

  ngOnInit(): void {
  }

}
