<app-header [current]="'about'"></app-header>

<div class="breadcrumb-section image-bg ">
  <div class="overlay"></div>
  <div class="breadcrumb-content container">
    <h1>About Us</h1>
  </div>
</div><!-- breadcrumb-section -->

<div class="about-us-section section-padding">
  <div class="container">
    <div class="section-title text-center">
      <h1>What We Do</h1>
      <h2>About Construction</h2>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="about-section-content">
          <h2>About Construction</h2>
          <p>
            Visha Consultants, Inc. is dedicated to provide geotechnical engineering and construction support services to,
            from residential construction project to multi million commercial construction project. Our firm has
            California registered geotechnical engineers, professional engineers and engineering geologists with
            over twenty years of working experience in the field of geotechnical engineering and engineering geology.
          </p>

          <p>
            We specialize in finding cost effective way to build residential foundations to multi story commercial
            building foundations and finding solution to resolve foundation distress problems.
            We also specialize in designing retaining walls, below grade structures, and performing slope stability,
            seismic, liquefaction, and fault analyses.
          </p>

          <p>
            Our engineers and geologists closely and directly work with County and City reviewers,
            project designers, and contractors to get our clients projects done quickly and while
            maintaining a good service quality.
          </p>

        </div>
      </div>
      <div class="col-md-6">
        <div class="about-us-image">
          <img class="img-responsive" src="images/about/about-img.jpg" alt="" >
        </div>
      </div>
    </div><!-- row -->
  </div><!-- container -->
</div><!-- features section -->

<div *ngIf="isShow" class="work-process image-bg section-padding">
  <div class="overlay"></div>
  <div class="container">
    <div class="section-title text-center">
      <h1>Why Choose Us</h1>
      <h2>Our Promise and Values</h2>
    </div>
    <div class="process-section">

      <div class="row">
        <div class="col-md-6 col-sm-12">
          <div class="work-proces left-content text-right">
            <div class="process-icon">
              <img class="img-responsive" src="images/process/proces1.png" alt="image">
            </div>
            <div class="process-info">
              <h4>Work Strategy</h4>
              <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod fore to tempor incididunt ut labore et dolore magna aliqua ut enim ad.</p>
            </div>
          </div>
          <div class="work-proces left-content text-right">
            <div class="process-icon">
              <img class="img-responsive" src="images/process/proces2.png" alt="image">
            </div>
            <div class="process-info">
              <h4>Best Quality</h4>
              <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod fore to tempor incididunt ut labore et dolore magna aliqua ut enim ad.</p>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-sm-12">

          <div class="work-proces">
            <div class="process-icon">
              <img class="img-responsive" src="images/process/proces3.png" alt="image">
            </div>
            <div class="process-info">
              <h4>Specialist Engineers</h4>
              <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod fore to tempor incididunt ut labore et dolore magna aliqua ut enim ad.</p>
            </div>
          </div>
          <div class="work-proces">
            <div class="process-icon">
              <img class="img-responsive" src="images/process/proces4.png" alt="image">
            </div>
            <div class="process-info">
              <h4>Skilled Workers</h4>
              <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod fore to tempor incididunt ut labore et dolore magna aliqua ut enim ad.</p>
            </div>
          </div>
        </div>
      </div>
    </div><!-- row -->
  </div><!-- container -->
</div><!-- process -->

<div *ngIf="isShow" class="team-section section-padding">
  <div class="container">
    <div class="section-title text-center">
      <h1>OUR Team</h1>
      <h2>We have Experienced Members</h2>
    </div>

    <div class="team-members">
      <div class="row">
        <div class="col-sm-3">
          <div class="team-member">
            <div class="member-image">
              <img class="img-responsive" src="images/team/4.jpg" alt="image">
            </div>
            <div class="team-info">
              <div class="member-info">
                <h4>Jhon Doe</h4>
                <p>Construction Manager</p>
              </div>
              <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod fore to tempor incididunt</p>
              <ul class="team-social list-inline">
                <li><a href="#"><i class="fa fa-facebook"></i></a></li>
                <li><a href="#"><i class="fa fa-twitter"></i></a></li>
                <li><a href="#"><i class="fa fa-linkedin"></i></a></li>
                <li><a href="#"><i class="fa fa-envelope"></i></a></li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-sm-3">
          <div class="team-member">
            <div class="member-image">
              <img class="img-responsive" src="images/team/3.jpg" alt="image">
            </div>
            <div class="team-info">
              <div class="member-info">
                <h4>Abir Ahnan</h4>
                <p>Chief Engineer</p>
              </div>
              <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod fore to tempor incididunt.</p>
              <ul class="team-social list-inline">
                <li><a href="#"><i class="fa fa-facebook"></i></a></li>
                <li><a href="#"><i class="fa fa-twitter"></i></a></li>
                <li><a href="#"><i class="fa fa-linkedin"></i></a></li>
                <li><a href="#"><i class="fa fa-envelope"></i></a></li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-sm-3">
          <div class="team-member">
            <div class="member-image">
              <img class="img-responsive" src="images/team/1.jpg" alt="image">
            </div>
            <div class="team-info">
              <div class="member-info">
                <h4>Mark Leon</h4>
                <p>Cheaf Colnsultant</p>
              </div>
              <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod fore to tempor incididunt.</p>
              <ul class="team-social list-inline">
                <li><a href="#"><i class="fa fa-facebook"></i></a></li>
                <li><a href="#"><i class="fa fa-twitter"></i></a></li>
                <li><a href="#"><i class="fa fa-linkedin"></i></a></li>
                <li><a href="#"><i class="fa fa-envelope"></i></a></li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-sm-3">
          <div class="team-member">
            <div class="member-image">
              <img class="img-responsive" src="images/team/2.jpg" alt="image">
            </div>
            <div class="team-info">
              <div class="member-info">
                <h4>Steve Clark</h4>
                <p>Project Manager</p>
              </div>
              <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod fore to tempor incididunt.</p>
              <ul class="team-social list-inline">
                <li><a href="#"><i class="fa fa-facebook"></i></a></li>
                <li><a href="#"><i class="fa fa-twitter"></i></a></li>
                <li><a href="#"><i class="fa fa-linkedin"></i></a></li>
                <li><a href="#"><i class="fa fa-envelope"></i></a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div><!-- container -->
</div><!-- team section -->

<div *ngIf="isShow" class="testimonial-section image-bg section-padding">
  <div class="overlay"></div>
  <div class="container">
    <div class="testimonial-slider">

      <div class="row testimonial text-center">
        <div class="col-sm-8 col-sm-offset-2">
          <div class="testimonial-image">
            <img class="img-responsive" src="images/testimonial/1.jpg" alt="image">
          </div>
          <div class="testimonial-info ">
            <p>Lorem Ipsum is simply dummy text of the printing and pesetting to industry. Lorem Ipsum has been the industry's standard dummy tet ever since they 1500s, </p>
            <h5>Jhon Doe | CEO, Eric Consultancy</h5>
          </div>
        </div>

      </div>
      <div class="row testimonial text-center">
        <div class="col-sm-8 col-sm-offset-2">
          <div class="testimonial-image">
            <img class="img-responsive" src="images/testimonial/2.jpg" alt="image">
          </div>
          <div class="testimonial-info">
            <p>Lorem Ipsum is simply dummy text of the printing and pesetting to industry. Lorem Ipsum has been the industry's standard dummy tet ever since they 1500s, </p>
            <h5>Mark Semouel | Vice Pricedent, KRBP</h5>
          </div>
        </div>

      </div>

      <div class="row testimonial text-center">
        <div class="col-sm-8 col-sm-offset-2">
          <div class="testimonial-image">
            <img class="img-responsive" src="images/testimonial/3.jpg" alt="image">
          </div>
          <div class="testimonial-info">
            <p>Lorem Ipsum is simply dummy text of the printing and pesetting to industry. Lorem Ipsum has been the industry's standard dummy tet ever since they 1500s, </p>
            <h5>Hall Melan | Developer, Artsell</h5>
          </div>
        </div>

      </div>

    </div>
  </div><!-- container -->
</div><!-- testimonial section -->

<app-footer></app-footer>
