<app-header [current]="'projects'"></app-header>

<div class="breadcrumb-section image-bg ">
  <div class="overlay"></div>
  <div class="breadcrumb-content container">
    <h1>Single Family Homes</h1>
  </div>
</div>

<div class="details-section section-padding ptremove">
  <div class="container">
    <div class="row">
      <div class="col-md-8 col-sm-12">
        <div id="product-carousel">
          <div class="carousel-image">
            <img class="img-responsive" src="images/project/custom-homes.png" alt="Image">
          </div>
          <div class="carousel-image">
            <img class="img-responsive" src="images/project/custom-homes2.png" alt="Image">
          </div>
          <div class="carousel-image">
            <img class="img-responsive" src="images/project/custom-homes3.png" alt="Image">
          </div>
          <div class="carousel-image">
            <img class="img-responsive" src="images/project/custom-homes4.png" alt="Image">
          </div>
        </div>
      </div>
      <div class="col-md-4 col-sm-12">
        <div class="details-info pt13">
          <p>Visha Consultants performed geotechnical investigation for over 500 single family homes construction projects
            involving new, one to five story residential structures; additions to existing residential structures;
            foundation upgrades and seismic retrofits of existing structures; mitigation of damage due to earth movement;
            groundwater seepage; and design and construction of retaining walls within California.</p>

        </div>
      </div>
    </div><!-- row -->
  </div><!-- container -->
</div><!-- details section -->

<app-footer></app-footer>
