import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-highrise-building',
  templateUrl: './highrise-building.component.html',
  styleUrls: ['./highrise-building.component.css']
})
export class HighriseBuildingComponent implements OnInit {

  constructor() { }
  isShow = false;

  ngOnInit(): void {
  }

}
