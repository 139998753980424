import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-healthcare-facilities',
  templateUrl: './healthcare-facilities.component.html',
  styleUrls: ['./healthcare-facilities.component.css']
})
export class HealthcareFacilitiesComponent implements OnInit {

  constructor() { }
  isShow = false;

  ngOnInit(): void {
  }

}
