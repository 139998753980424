import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-multi-residence-development',
  templateUrl: './multi-residence-development.component.html',
  styleUrls: ['./multi-residence-development.component.css']
})
export class MultiResidenceDevelopmentComponent implements OnInit {

  constructor() { }
  isShow = false;

  ngOnInit(): void {
  }

}
