<div class="cta-section image-bg">
  <div class="call-to-action text-left">
    <div class="container">
      <div class="brand-info">
        <h3>Looking for a constructor for your next project ?</h3>
        <a href="/contact-us" class="btn btn-primary">Contact Us</a>
      </div>
    </div>
  </div>
</div><!-- clients-section -->
