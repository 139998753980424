<app-header [current]="'projects'"></app-header>

<div class="breadcrumb-section image-bg ">
  <div class="overlay"></div>
  <div class="breadcrumb-content container">
    <h1>Our Projects</h1>
  </div>
</div><!-- breadcrumb-section -->


<div class="recent-projects section-padding">
  <div class="container">
    <div class="section-title text-center">
      <div class="container">
        <h1>Our Projects</h1>
        <h2>We already completed</h2>
      </div>
    </div>
    <div class="row" >
      <div id="projects">

        <!-- Single Family Homes -->
        <div class="cbp-item  project-content filter-hotels">
          <div class="project-title">
            <h3><a href="/single-home">Single Family Homes</a></h3>
            <span *ngIf="isShow">Clients: Golden State Properties</span>
          </div>
          <div class="project-item">
            <a href="images/project/custom-homes.png" class="image-link">
              <img class="img-responsive" src="images/project/custom-homes.png" alt="" />
            </a>
          </div>
          <div class="project-info">
            <p></p>
          </div>
        </div>

        <!-- Highrise Buildings -->
        <div class="cbp-item  project-content filter-hotels">
          <div class="project-title">
            <h3><a href="/highrise-building">Highrise Buildings</a></h3>
            <span *ngIf="isShow">Clients: Golden State Properties</span>
          </div>
          <div class="project-item">
            <a href="images/project/high-rise.jpg" class="image-link">
              <img class="img-responsive" src="images/project/high-rise.jpg" alt="" />
            </a>
          </div>
          <div class="project-info">
            <p></p>
          </div>
        </div>

        <!-- Hotels -->
        <div class="cbp-item  project-content filter-hotels">
          <div class="project-title">
            <h3><a href="/hotels">Hotels</a></h3>
            <span *ngIf="isShow">Clients: Golden State Properties</span>
          </div>
          <div class="project-item">
            <a href="images/project/hyatt-palace.png" class="image-link">
              <img class="img-responsive" src="images/project/hyatt-palace.png" alt="" />
            </a>
          </div>
          <div class="project-info">
            <p></p>
          </div>
        </div>

        <!-- Healthcare Facilities -->
        <div class="cbp-item  project-content filter-hotels">
          <div class="project-title">
            <h3><a href="/healthcare-facilities">Healthcare Facilities</a></h3>
            <span *ngIf="isShow">Clients: Golden State Properties</span>
          </div>
          <div class="project-item">
            <a href="images/project/medical-building.jpg" class="image-link">
              <img class="img-responsive" src="images/project/medical-building.jpg" alt="" />
            </a>
          </div>
          <div class="project-info">
            <p></p>
          </div>
        </div>

        <!-- Commercial and Government Facilities -->
        <div class="cbp-item  project-content filter-hotels">
          <div class="project-title">
            <h3><a href="/commercial-and-government">Commercial and Government Facilities</a></h3>
            <span *ngIf="isShow">Clients: Golden State Properties</span>
          </div>
          <div class="project-item">
            <a href="images/project/commercial.jpg" class="image-link">
              <img class="img-responsive" src="images/project/commercial.jpg" alt="" />
            </a>
          </div>
          <div class="project-info">
            <p></p>
          </div>
        </div>


        <!-- Multi Residence Development -->
        <div class="cbp-item  project-content filter-hotels">
          <div class="project-title">
            <h3><a href="/multi-residence-development">Multi Residence Development</a></h3>
            <span *ngIf="isShow">Clients: Golden State Properties</span>
          </div>
          <div class="project-item">
            <a href="images/project/apartment.png" class="image-link">
              <img class="img-responsive" src="images/project/apartment.png" alt="" />
            </a>
          </div>
          <div class="project-info">
            <p></p>
          </div>
        </div>


        <!-- Schools -->
        <div class="cbp-item  project-content filter-hotels">
          <div class="project-title">
            <h3><a href="/schools">Schools</a></h3>
            <span *ngIf="isShow">Clients: Golden State Properties</span>
          </div>
          <div class="project-item">
            <a href="images/project/school.jpg" class="image-link">
              <img class="img-responsive" src="images/project/school.jpg" alt="" />
            </a>
          </div>
          <div class="project-info">
            <p></p>
          </div>
        </div>


        <!-- Warehouse Construction -->
        <div class="cbp-item  project-content filter-hotels">
          <div class="project-title">
            <h3><a href="/warehouse-construction">Warehouse Construction</a></h3>
            <span *ngIf="isShow">Clients: Golden State Properties</span>
          </div>
          <div class="project-item">
            <a href="images/project/warehouse.jpg" class="image-link">
              <img class="img-responsive" src="images/project/warehouse.jpg" alt="" />
            </a>
          </div>
          <div class="project-info">
            <p></p>
          </div>
        </div>

        <!-- Churches and Cemeteries -->
        <div class="cbp-item  project-content filter-hotels">
          <div class="project-title">
            <h3><a href="/churches-and-cemeteries">Churches and Cemeteries</a></h3>
            <span *ngIf="isShow">Clients: Golden State Properties</span>
          </div>
          <div class="project-item">
            <a href="images/project/cemetary.jpg" class="image-link">
              <img class="img-responsive" src="images/project/cemetary.jpg" alt="" />
            </a>
          </div>
          <div class="project-info">
            <p></p>
          </div>
        </div>

      </div>
    </div>
  </div>
</div><!-- recent projects -->

<app-footer></app-footer>
