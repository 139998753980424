<app-header [current]="'projects'"></app-header>

<div class="breadcrumb-section image-bg ">
  <div class="overlay"></div>
  <div class="breadcrumb-content container">
    <h1>Multi Residence Development</h1>
  </div>
</div>


<div class="details-section section-padding ptremove">
  <div class="container">
    <div class="row">
      <div class="col-md-8 col-sm-12">
        <div id="product-carousel">
          <div class="carousel-image">
            <img class="img-responsive" src="images/project/hyatt-palace.png" alt="Image">
          </div>
        </div>
      </div>
      <div class="col-md-4 col-sm-12">
        <div class="details-info">

          <div class="details-list">
            <ul>
              <li>New 40-unit multi family buildings construction, San Pablo Dam Road, in Richmond, California.</li>
              <li>10-unit town houses construction, Wilkins Avenue, Napa, California.</li>
              <li>7-unit three story condominium construction, Walnut Blvd, Walnut Creek, California.</li>
              <li>Single family residential subdivision construction, Magnolia avenue, Newark, California.</li>
              <li>10-unit single family residential subdivision construction, Ferrell Avenue, Gilroy, California.</li>
              <li>Shaver Apartment complex construction in San Rafael, California.</li>
              <li>Single Family homes subdivision construction in Gilroy, California.</li>
              <li>New 12-Unit residential subdivision construction, Castro Valley, California.</li>
            </ul>
          </div>

        </div>
      </div>
    </div><!-- row -->
  </div><!-- container -->
</div><!-- details section -->

<app-footer></app-footer>
