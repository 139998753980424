<app-header [current]="'services'"></app-header>

<div class="breadcrumb-section image-bg ">
  <div class="overlay"></div>
  <div class="breadcrumb-content container">
    <h1>Our Services</h1>
  </div>
</div><!-- breadcrumb-section -->

<div class="service-section section-padding">
  <div class="container">
    <div class="section-title text-center">
      <h1>Our Services</h1>
      <h2>We Offer Our Clients</h2>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="service-content">
          <h2>Services</h2>
          <p>
            Visha consultants has been performing numerous Geotechnical Engineering Consulting services throughout
            the Bay Area and the Sacramento region from individual home owners to multi million dollar businesses.
            Following are the key geotechnical engineering services we provide:
          </p>

          <ul class="custom-list">
            <li>Geotechnical Investigation (Soils) Report</li>
            <li>Conventional and Segmental Retaining Wall Design</li>
            <li>Foundation Design with Bearing Capacity Values</li>
            <li>Pavement Design (Concrete and Asphalt Pavements)</li>
            <li>Geotechnical Feasibility Studies & Site Investigations</li>
            <li>Slope Stability Analysis & Landslide Study</li>
            <li>Earthquake Analyses with Liquefaction Study</li>
            <li>Develop Site Specific Seismic Ground Motion Parameters</li>
            <li>Installing Groundwater Monitoring Wells and Percolation Tests</li>
            <li>Building Settlement Evaluation and Inspection</li>
            <li>Provide Field Inspection and Testing Services during Earthwork Fill Placement & Compaction.</li>
          </ul>
          <br/>
          <p>
            We also perform field inspection and testing services during earthwork fill placement and compaction (grading), testing of street subgrade,
            aggregate base, and asphalt concrete, observation and testing of soil fill placement and compaction.
          </p>

        </div>
      </div>
      <div class="col-md-6">
        <div class="about-us-image">
          <img class="img-responsive" src="images/service/service-img.jpg" alt="" >
        </div>
      </div>
    </div><!-- row -->
  </div><!-- container -->
</div><!-- features section -->

<div *ngIf="isShow" class="service-section section-padding">
  <div class="container">
    <div class="section-title text-center">
      <h1>Our Services</h1>
      <h2>We Offer Our Clients</h2>
    </div>
    <div class="service-content">
      <div class="services">
        <div class="row">
          <div class="col-sm-6 col-md-4">
            <div class="service crane-lifting image-bg">
              <div class="overlay"></div>
              <div class="image-box">
                <img class="img-responsive" src="images/service/1.png" alt="Image">
              </div>
              <div class="box-title">
                <h3>Construction</h3>
                <p>Lorem ipsum dolor sit amet, cons. adipisicing elit, sed do eiusmod. </p>
              </div>
            </div>
          </div>
          <div class="col-sm-6 col-md-4">
            <div class="service home-plumbing image-bg">
              <div class="overlay"></div>
              <div class="image-box">
                <img class="img-responsive" src="images/service/2.png" alt="Image">
              </div>
              <div class="box-title">
                <h3>Metal Roofing</h3>
                <p>Lorem ipsum dolor sit amet, cons. adipisicing elit, sed do eiusmod. </p>
              </div>
            </div>
          </div>
          <div class="col-sm-6 col-md-4">
            <div class="service home-plumbing image-bg">
              <div class="overlay"></div>
              <div class="image-box">
                <img class="img-responsive" src="images/service/4.png" alt="Image">
              </div>
              <div class="box-title">
                <h3>Architechture</h3>
                <p>Lorem ipsum dolor sit amet, cons. adipisicing elit, sed do eiusmod. </p>
              </div>
            </div>
          </div>
          <div class="col-sm-6 col-md-4">
            <div class="service house-plans image-bg">
              <div class="overlay"></div>
              <div class="image-box">
                <img class="img-responsive" src="images/service/3.png" alt="Image">
              </div>
              <div class="box-title">
                <h3>Consulting</h3>
                <p>Lorem ipsum dolor sit amet, cons. adipisicing elit, sed do eiusmod.</p>
              </div>
            </div>
          </div>
          <div class="col-sm-6 col-md-4">
            <div class="service house-plans image-bg">
              <div class="overlay"></div>
              <div class="image-box">
                <img class="img-responsive" src="images/service/5.png" alt="Image">
              </div>
              <div class="box-title">
                <h3>Interior Design</h3>
                <p>Lorem ipsum dolor sit amet, cons. adipisicing elit, sed do eiusmod.</p>
              </div>
            </div>
          </div>
          <div class="col-sm-6 col-md-4">
            <div class="service home-plumbing image-bg">
              <div class="overlay"></div>
              <div class="image-box">
                <img class="img-responsive" src="images/service/6.png" alt="Image">
              </div>
              <div class="box-title">
                <h3>Green Building</h3>
                <p>Lorem ipsum dolor sit amet, cons. adipisicing elit, sed do eiusmod. </p>
              </div>
            </div>
          </div>
        </div>
      </div><!-- services -->
    </div>
  </div><!-- container -->
</div><!-- service section -->

<div *ngIf="isShow" class="work-process image-bg section-padding">
  <div class="overlay"></div>
  <div class="container">
    <div class="section-title text-center">
      <h1>Why Choose Us</h1>
      <h2>Our Promise and Values</h2>
    </div>
    <div class="process-section">

      <div class="row">
        <div class="col-md-6 col-sm-12">
          <div class="work-proces left-content text-right">
            <div class="process-icon">
              <img class="img-responsive" src="images/process/proces1.png" alt="image">
            </div>
            <div class="process-info">
              <h4>Work Strategy</h4>
              <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod fore to tempor incididunt ut labore et dolore magna aliqua ut enim ad.</p>
            </div>
          </div>
          <div class="work-proces left-content text-right">
            <div class="process-icon">
              <img class="img-responsive" src="images/process/proces2.png" alt="image">
            </div>
            <div class="process-info">
              <h4>Best Quality</h4>
              <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod fore to tempor incididunt ut labore et dolore magna aliqua ut enim ad.</p>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-sm-12">

          <div class="work-proces">
            <div class="process-icon">
              <img class="img-responsive" src="images/process/proces3.png" alt="image">
            </div>
            <div class="process-info">
              <h4>Specialist Engineers</h4>
              <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod fore to tempor incididunt ut labore et dolore magna aliqua ut enim ad.</p>
            </div>
          </div>
          <div class="work-proces">
            <div class="process-icon">
              <img class="img-responsive" src="images/process/proces4.png" alt="image">
            </div>
            <div class="process-info">
              <h4>Skilled Workers</h4>
              <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod fore to tempor incididunt ut labore et dolore magna aliqua ut enim ad.</p>
            </div>
          </div>
        </div>
      </div>
    </div><!-- row -->
  </div><!-- container -->
</div><!-- process -->

<div *ngIf="isShow" class="recent-projects section-padding">
  <div class="container">
    <div class="section-title text-center">
      <div class="container">
        <h1>Recent Projects</h1>
        <h2>We already completed</h2>
      </div>
    </div>
    <div class="row" >
      <div class="col-sm-4 project-content">
        <div class="project-title">
          <h3><a href="project-details.html">Nam liber tempor cum</a></h3>
          <span>Clients: Dreamer</span>
        </div>
        <div class="project-item">
          <a href="images/project/1.jpg" class="image-link"><img class="img-responsive" src="images/project/1.jpg" alt="" /></a>
        </div>
        <div class="project-info">
          <p>Duis autem vel eum iriure dolor in hendrerit in vulputate velit</p>
        </div>
      </div>
      <div class="col-sm-4  project-content">
        <div class="project-title">
          <h3><a href="project-details.html">Lorem ipsum dolor sit</a></h3>
          <span>Clients: Asthetic</span>
        </div>
        <div class="project-item">
          <a href="images/project/2.jpg" class="image-link"><img class="img-responsive" src="images/project/2.jpg" alt="" /></a>
        </div>
        <div class="project-info">
          <p>Nam liber tempor cum soluta nobis eleifend option congue nihil</p>
        </div>
      </div>
      <div class="col-sm-4  project-content">
        <div class="project-title">
          <h3><a href="project-details.html">Legentis in iis qui facit</a></h3>
          <span>Clients: Martin</span>
        </div>
        <div class="project-item">
          <a href="images/project/3.jpg" class="image-link"><img class="img-responsive" src="images/project/3.jpg" alt="" /></a>
        </div>
        <div class="project-info">
          <p>Typi non habent claritatem insitam; est usus legentis in iis qui facit</p>
        </div>
      </div>
      <div class="col-sm-4 project-content">
        <div class="project-title">
          <h3><a href="project-details.html">Duis autem vel eum </a>  </h3>
          <span>Clients: Marbel</span>
        </div>
        <div class="project-item">
          <a href="images/project/4.jpg" class="image-link"><img class="img-responsive" src="images/project/4.jpg" alt="" /></a>
        </div>
        <div class="project-info">
          <p>Mirum est notare quam littera gothica, quam nunc putamus parum</p>
        </div>
      </div>
      <div class="col-sm-4 project-content">
        <div class="project-title">
          <h3><a href="project-details.html">Lorem ipsum dolor sit</a></h3>
          <span>Clients: Asthetic</span>
        </div>
        <div class="project-item">
          <a href="images/project/2.jpg" class="image-link"><img class="img-responsive" src="images/project/5.jpg" alt="" /></a>
        </div>
        <div class="project-info">
          <p>Nam liber tempor cum soluta nobis eleifend option congue nihil</p>
        </div>
      </div>
      <div class="col-sm-4 project-content">
        <div class="project-title">
          <h3><a href="project-details.html">Legentis in iis qui facit</a></h3>
          <span>Clients: Martin</span>
        </div>
        <div class="project-item">
          <a href="images/project/3.jpg" class="image-link"><img class="img-responsive" src="images/project/6.jpg" alt="" /></a>
        </div>
        <div class="project-info">
          <p>Typi non habent claritatem insitam; est usus legentis in iis qui facit</p>
        </div>
      </div>
    </div>
  </div>
</div><!-- recent projects -->


<div *ngIf="isShow" class="testimonial-section image-bg section-padding">
  <div class="overlay"></div>
  <div class="container">
    <div class="testimonial-slider">

      <div class="row testimonial text-center">
        <div class="col-sm-8 col-sm-offset-2">
          <div class="testimonial-image">
            <img class="img-responsive" src="images/testimonial/1.jpg" alt="image">
          </div>
          <div class="testimonial-info ">
            <p>Lorem Ipsum is simply dummy text of the printing and pesetting to industry. Lorem Ipsum has been the industry's standard dummy tet ever since they 1500s, </p>
            <h5>Jhon Doe | CEO, Eric Consultancy</h5>
          </div>
        </div>

      </div>
      <div class="row testimonial text-center">
        <div class="col-sm-8 col-sm-offset-2">
          <div class="testimonial-image">
            <img class="img-responsive" src="images/testimonial/2.jpg" alt="image">
          </div>
          <div class="testimonial-info">
            <p>Lorem Ipsum is simply dummy text of the printing and pesetting to industry. Lorem Ipsum has been the industry's standard dummy tet ever since they 1500s, </p>
            <h5>Mark Semouel | Vice Pricedent, KRBP</h5>
          </div>
        </div>

      </div>

      <div class="row testimonial text-center">
        <div class="col-sm-8 col-sm-offset-2">
          <div class="testimonial-image">
            <img class="img-responsive" src="images/testimonial/3.jpg" alt="image">
          </div>
          <div class="testimonial-info">
            <p>Lorem Ipsum is simply dummy text of the printing and pesetting to industry. Lorem Ipsum has been the industry's standard dummy tet ever since they 1500s, </p>
            <h5>Hall Melan | Developer, Artsell</h5>
          </div>
        </div>

      </div>

    </div>
  </div><!-- container -->
</div><!-- testimonial section -->

<app-footer></app-footer>
