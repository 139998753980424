<app-header [current]="'home'"></app-header>

<div class="home-section image-bg">
  <div class="overlay"></div>
  <div class="home-content">
    <div class="container">
      <div id="home-slider" class="carousel slide carousel-fade" data-ride="carousel">
        <div class="carousel-inner" role="listbox">
          <div class="item active">
            <h2>Looking for a <span>quality</span> consulting service ?</h2>
            <a href="/contact-us" class="btn btn-primary">Contact Us</a>
          </div>
          <div class="item">
            <h2>We are <span>specialized</span> in geotech consulting</h2>
            <a href="/contact-us" class="btn btn-primary">Contact Us</a>
          </div>
        </div>
        <a class="carousel-left" href="#home-slider" data-slide="prev"><i class="fa fa-angle-left"></i></a>
        <a class="carousel-right" href="#home-slider" data-slide="next"><i class="fa fa-angle-right"></i></a>
      </div>

      <div class="services">
        <div class="row">
          <div class="col-md-4">
            <div class="service crane-lifting image-bg">
              <div class="overlay"></div>
              <div class="image-box">
                <img class="img-responsive" src="images/service/1.png" alt="Image">
              </div>
              <div class="box-title">
                <h3 class="widget-title" (click)="redirect('projects')">Projects</h3>
              </div>
            </div>
          </div>
          <div class="col-md-4 ">
            <div class="service home-plumbing image-bg">
              <div class="overlay"></div>
              <div class="image-box">
                <img class="img-responsive" src="images/service/2.png" alt="Image">
              </div>
              <div class="box-title">
                <h3 class="widget-title" (click)="redirect('careers')">Careers</h3>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="service house-plans image-bg">
              <div class="overlay"></div>
              <div class="image-box">
                <img class="img-responsive" src="images/service/5.png" alt="Image">
              </div>
              <div class="box-title">
                <h3 class="widget-title" (click)="redirect('services')">Services</h3>
              </div>
            </div>
          </div>
        </div>
      </div><!-- services -->
    </div><!-- container -->
  </div><!-- home content -->

</div><!-- home -->


<div class="features-section section-padding">
  <div class="container">
    <div class="row">
      <div class="col-sm-6 col-md-4">
        <div class="features">
          <div class="feature-icon">
            <i class="fa fa-user-circle" aria-hidden="true"></i>
          </div>

          <div class="feature-text">
            <h4>Consulting</h4>
            <app-app-read-more
              [content]="consulting"
              [limit]="90"
              [completeWords]="true"></app-app-read-more>
          </div>
        </div>
      </div>
      <div class="col-sm-6 col-md-4">
        <div class="features">
          <div class="feature-icon">
            <i class="fa fa-cogs" aria-hidden="true"></i>
          </div>

          <div class="feature-text">
            <h4>Service Areas</h4>
            <p></p>
            <app-app-read-more
              [content]="serviceAreas"
              [limit]="100"
              [completeWords]="true"></app-app-read-more>
          </div>
        </div>
      </div>
      <div class="col-sm-6 col-md-4">
        <div class="features">
          <div class="feature-icon">
            <i class="fa fa-briefcase" aria-hidden="true"></i>
          </div>

          <div class="feature-text">
            <h4>Professional Services</h4>
            <app-app-read-more [content]="professionalServices" [limit]="100"
                               [completeWords]="true"></app-app-read-more>
          </div>
        </div>
      </div>
    </div><!-- row -->
  </div><!-- container -->
</div><!-- features section -->

<div class="work-process image-bg section-padding">
  <div class="overlay"></div>
  <div class="container">
    <div class="section-title text-center">
      <h1>Why Choose Us</h1>
      <h2>Our Promise and Values</h2>
    </div>
    <div class="process-section">

      <div class="row">
        <div class="col-md-6 col-sm-12">
          <div class="work-proces left-content text-right">
            <div class="process-icon">
              <img class="img-responsive" src="images/process/geo_technology.png" alt="image">
            </div>
            <div class="process-info">
              <h4>Geotechnical</h4>
              <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod fore to tempor incididunt ut
                labore et dolore magna aliqua ut enim ad.</p>
            </div>
          </div>
          <div class="work-proces left-content text-right">
            <div class="process-icon">
              <img class="img-responsive" src="../../../images/process/geoscience.png" alt="image">
            </div>
            <div class="process-info">
              <h4>Geo Science</h4>
              <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod fore to tempor incididunt ut
                labore et dolore magna aliqua ut enim ad.</p>
            </div>

          </div>
          <div class="work-proces left-content text-right">
            <div class="process-icon">
              <img class="img-responsive" src="images/process/material_testing.png" alt="image">
            </div>
            <div class="process-info">
              <h4>Material Testing</h4>
              <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod fore to tempor incididunt ut
                labore et dolore magna aliqua ut enim ad.</p>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-sm-12">

          <div class="work-proces">
            <div class="process-icon">
              <img class="img-responsive" src="images/process/construction_support.png" alt="image">
            </div>
            <div class="process-info">
              <h4>Construction Support</h4>
              <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod fore to tempor incididunt ut
                labore et dolore magna aliqua ut enim ad.</p>
            </div>
          </div>
          <div class="work-proces">
            <div class="process-icon">
              <img class="img-responsive" src="images/process/special_inspection.png" alt="image">
            </div>
            <div class="process-info">
              <h4>Special Inspection</h4>
              <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod fore to tempor incididunt ut
                labore et dolore magna aliqua ut enim ad.</p>
            </div>
          </div>
          <div class="work-proces">
            <div class="process-icon">
              <img class="img-responsive" src="../../../images/process/seismic_evaluation.png" alt="image">
            </div>
            <div class="process-info">
              <h4>Seismic Evaluation</h4>
              <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod fore to tempor incididunt ut
                labore et dolore magna aliqua ut enim ad.</p>
            </div>
          </div>
        </div>
      </div>
    </div><!-- row -->
  </div><!-- container -->
</div><!-- process -->

<app-footer></app-footer>

