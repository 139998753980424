import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-warehouse-construction',
  templateUrl: './warehouse-construction.component.html',
  styleUrls: ['./warehouse-construction.component.css']
})
export class WarehouseConstructionComponent implements OnInit {

  constructor() { }
  isShow = false;

  ngOnInit(): void {
  }

}
