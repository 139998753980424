
<app-header [current]="'gallery'"></app-header>


<!-- content -->
<div id="page-content" class="container-fluid">
  <!-- projects -->
  <section id="portfolio" class="no-gutter">
    <ul class="row portfolio list-unstyled gallery" id="grid">

      <!-- project -->
      <li class="col-xs-6 col-sm-3 project" data-groups='["portrait"]'>
        <figure class="portfolio-item">
          <div class="hovereffect">
            <img class="img-responsive" src="images/project1.jpg" alt="">
            <a href="images/project1.jpg">
              <div class="overlay">
                <div class="hovereffect-title">
                  <h5>Photo 1</h5>
                </div>
              </div>
            </a>
          </div>
        </figure>
      </li>
      <!-- / project -->

      <!-- project -->
      <li class="col-xs-6 col-sm-3 project portrait-img" data-groups='["wedding"]'>
        <figure class="portfolio-item">
          <div class="hovereffect">
            <img class="img-responsive" src="images/project2.jpg" alt="">
            <a href="images/project2.jpg">
              <div class="overlay">
                <div class="hovereffect-title">
                  <h5>Photo 2</h5>
                </div>
              </div>
            </a>
          </div>
        </figure>
      </li>
      <!-- / project -->

      <!-- project -->
      <li class="col-xs-6 col-sm-3 project" data-groups='["wedding"]'>
        <figure class="portfolio-item">
          <div class="hovereffect">
            <img class="img-responsive" src="images/project3.jpg" alt="">
            <a href="images/project3.jpg">
              <div class="overlay">
                <div class="hovereffect-title">
                  <h5>Photo 3</h5>
                </div>
              </div>
            </a>
          </div>
        </figure>
      </li>
      <!-- / project -->

      <!-- project -->
      <li class="col-xs-6 col-sm-3 project" data-groups='["architecture", "landscape", "photography"]'>
        <figure class="portfolio-item">
          <div class="hovereffect">
            <img class="img-responsive" src="images/project4.jpg" alt="">
            <a href="images/project4.jpg">
              <div class="overlay">
                <div class="hovereffect-title">
                  <h5>Photo 4</h5>
                </div>
              </div>
            </a>
          </div>
        </figure>
      </li>
      <!-- / project -->

      <!-- project -->
      <li class="col-xs-6 col-sm-3 project" data-groups='["architecture", "landscape", "photography"]'>
        <figure class="portfolio-item">
          <div class="hovereffect">
            <img class="img-responsive" src="images/project5.jpg" alt="">
            <a href="images/project5.jpg">
              <div class="overlay">
                <div class="hovereffect-title">
                  <h5>Photo 5</h5>
                </div>
              </div>
            </a>
          </div>
        </figure>
      </li>
      <!-- / project -->

      <!-- project -->
      <li class="col-xs-6 col-sm-3 project portrait-img" data-groups='["portrait"]'>
        <figure class="portfolio-item">
          <div class="hovereffect">
            <img class="img-responsive" src="images/project6.jpg" alt="">
            <a href="images/project6.jpg">
              <div class="overlay">
                <div class="hovereffect-title">
                  <h5>Photo 6</h5>
                </div>
              </div>
            </a>
          </div>
        </figure>
      </li>
      <!-- / project -->

      <!-- project -->
      <li class="col-xs-6 col-sm-3 project" data-groups='["portrait", "wedding"]'>
        <figure class="portfolio-item">
          <div class="hovereffect">
            <img class="img-responsive" src="images/project7.jpg" alt="">
            <a href="images/project7.jpg">
              <div class="overlay">
                <div class="hovereffect-title">
                  <h5>Photo 7</h5>
                </div>
              </div>
            </a>
          </div>
        </figure>
      </li>
      <!-- / project -->

      <!-- project -->
      <li class="col-xs-6 col-sm-3 project portrait-img" data-groups='["wedding", "photography"]'>
        <figure class="portfolio-item">
          <div class="hovereffect">
            <img class="img-responsive" src="images/project8.jpg" alt="">
            <a href="images/project8.jpg">
              <div class="overlay">
                <div class="hovereffect-title">
                  <h5>Photo 8</h5>
                </div>
              </div>
            </a>
          </div>
        </figure>
      </li>
      <!-- / project -->

      <!-- project -->
      <li class="col-xs-6 col-sm-3 project" data-groups='["portrait"]'>
        <figure class="portfolio-item">
          <div class="hovereffect">
            <img class="img-responsive" src="images/project9.jpg" alt="">
            <a href="images/project9.jpg">
              <div class="overlay">
                <div class="hovereffect-title">
                  <h5>Photo 9</h5>
                </div>
              </div>
            </a>
          </div>
        </figure>
      </li>
      <!-- / project -->

      <!-- project -->
      <li class="col-xs-6 col-sm-3 project" data-groups='["portrait", "landscape"]'>
        <figure class="portfolio-item">
          <div class="hovereffect">
            <img class="img-responsive" src="images/project10.jpg" alt="">
            <a href="images/project10.jpg">
              <div class="overlay">
                <div class="hovereffect-title">
                  <h5>Photo 10</h5>
                </div>
              </div>
            </a>
          </div>
        </figure>
      </li>
      <!-- / project -->

      <!-- project -->
      <li class="col-xs-6 col-sm-3 project portrait-img" data-groups='["portrait", "photography"]'>
        <figure class="portfolio-item">
          <div class="hovereffect">
            <img class="img-responsive" src="images/project11.jpg" alt="">
            <a href="images/project11.jpg">
              <div class="overlay">
                <div class="hovereffect-title">
                  <h5>Photo 11</h5>
                </div>
              </div>
            </a>
          </div>
        </figure>
      </li>
      <!-- / project -->

      <!-- project -->
      <li class="col-xs-6 col-sm-3 project" data-groups='["photography"]'>
        <figure class="portfolio-item">
          <div class="hovereffect">
            <img class="img-responsive" src="images/project12.jpg" alt="">
            <a href="images/project12.jpg">
              <div class="overlay">
                <div class="hovereffect-title">
                  <h5>Photo 12</h5>
                </div>
              </div>
            </a>
          </div>
        </figure>
      </li>
      <!-- / project -->

      <!-- project -->
      <li class="col-xs-6 col-sm-3 project" data-groups='["portrait"]'>
        <figure class="portfolio-item">
          <div class="hovereffect">
            <img class="img-responsive" src="images/project13.jpg" alt="">
            <a href="images/project13.jpg">
              <div class="overlay">
                <div class="hovereffect-title">
                  <h5>Photo 13</h5>
                </div>
              </div>
            </a>
          </div>
        </figure>
      </li>
      <!-- / project -->

      <!-- project -->
      <li class="col-xs-6 col-sm-3 project" data-groups='["photography"]'>
        <figure class="portfolio-item">
          <div class="hovereffect">
            <img class="img-responsive" src="images/project14.jpg" alt="">
            <a href="images/project14.jpg">
              <div class="overlay">
                <div class="hovereffect-title">
                  <h5>Photo 14</h5>
                </div>
              </div>
            </a>
          </div>
        </figure>
      </li>
      <!-- / project -->

      <!-- project -->
      <li class="col-xs-6 col-sm-3 project" data-groups='["architecture"]'>
        <figure class="portfolio-item">
          <div class="hovereffect">
            <img class="img-responsive" src="images/project15.jpg" alt="">
            <a href="images/project15.jpg">
              <div class="overlay">
                <div class="hovereffect-title">
                  <h5>Photo 15</h5>
                </div>
              </div>
            </a>
          </div>
        </figure>
      </li>
      <!-- / project -->

      <!-- project -->
      <li class="col-xs-6 col-sm-3 project" data-groups='["landscape"]'>
        <figure class="portfolio-item">
          <div class="hovereffect">
            <img class="img-responsive" src="images/project16.jpg" alt="">
            <a href="images/project16.jpg">
              <div class="overlay">
                <div class="hovereffect-title">
                  <h5>Photo 16</h5>
                </div>
              </div>
            </a>
          </div>
        </figure>
      </li>
      <!-- / project -->

      <!-- sizer -->
      <li class="col-xs-6 col-sm-3 shuffle_sizer"></li>
    </ul> <!-- / projects -->
  </section>
  <!-- / portfolio section 4col -->
</div><!-- / page-content -->
<!-- / content -->

<app-footer></app-footer>
