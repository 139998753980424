<app-header [current]="'projects'"></app-header>

<div class="breadcrumb-section image-bg ">
  <div class="overlay"></div>
  <div class="breadcrumb-content container">
    <h1>Our Projects</h1>
  </div>
</div><!-- breadcrumb-section -->


<div class="recent-projects section-padding">
  <div class="container">
    <div class="section-title text-center">
      <div class="container">
        <h1>Our Projects</h1>
      </div>
    </div>
    <div class="row text-center">
      <h5>Our completed projects will available here soon..</h5>
    </div>
  </div>
</div><!-- recent projects -->

<app-footer></app-footer>
