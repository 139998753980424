<app-header [current]="'projects'"></app-header>

<div class="breadcrumb-section image-bg ">
  <div class="overlay"></div>
  <div class="breadcrumb-content container">
    <h1>Warehouse Construction</h1>
  </div>
</div>


<div class="details-section section-padding ptremove">
  <div class="container">
    <div class="row">
      <div class="col-md-8 col-sm-12">
        <div id="product-carousel">
          <div class="carousel-image">
            <img class="img-responsive" src="images/project/warehouse.jpg" alt="Image">
          </div>
        </div>
      </div>
      <div class="col-md-4 col-sm-12">
        <div class="details-info">

          <div class="details-list">
            <ul>
              <li>Approximately 8,000 square feet pre engineered farms delivery and distribution facility
                in Santa Ana Avenue, Sacramento, California. </li>
              <li>Specs warehouse building construction with about 50,000 square feet floor area, Sky Creek Road, in Sacramento, California.</li>
              <li>Two multipurpose tilt up warehouses construction with approximately 10,000 square feet floor area,
                Poppy House Road, in Rio Vista, California.</li>
              <li>New 10,000 square feet metal warehouse building construction, Lewis Avenue, Union City, California.</li>
              <li>New 84,000 square feet showroom and warehouse building, Power Inn Road, Sacramento, California.</li>
            </ul>
          </div>

        </div>
      </div>
    </div><!-- row -->
  </div><!-- container -->
</div><!-- details section -->

<app-footer></app-footer>
