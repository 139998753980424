<app-header [current]="'projects'"></app-header>

<div class="breadcrumb-section image-bg ">
  <div class="overlay"></div>
  <div class="breadcrumb-content container">
    <h1>Hotels</h1>
  </div>
</div>


<div class="details-section section-padding ptremove">
  <div class="container">
    <div class="row">
      <div class="col-md-8 col-sm-12">
        <div id="product-carousel">
          <div class="carousel-image">
            <img class="img-responsive" src="images/project/apartment.png" alt="Image">
          </div>
        </div>
      </div>
      <div class="col-md-4 col-sm-12">
        <div class="details-info">

          <div class="details-list">
            <ul>
              <li>Avid Hotel construction with 4-story high building and 95-geust rooms, Churchill Downs Avenue, Woodland, California.</li>
              <li>3-story high, Hyatt Place Novato Hotel Construction in Novato, California.</li>
            </ul>
          </div>

        </div>
      </div>
    </div><!-- row -->
  </div><!-- container -->
</div><!-- details section -->

<app-footer></app-footer>
