<app-header [current]="'projects'"></app-header>

<div class="breadcrumb-section image-bg ">
  <div class="overlay"></div>
  <div class="breadcrumb-content container">
    <h1>Commercial and Government Facilities</h1>
  </div>
</div>


<div class="details-section section-padding ptremove">3.
  <div class="container">
    <div class="row">
      <div class="col-md-8 col-sm-12">
        <div id="product-carousel">
          <div class="carousel-image">
            <img class="img-responsive" src="images/project/commercial.jpg" alt="Image">
          </div>
        </div>
      </div>
      <div class="col-md-4 col-sm-12">
        <div class="details-info">

          <div class="details-list">
            <ul>
              <li>Several generator pads construction, Naval Radio Transmission Facility, Dixon, California.</li>
              <li>Several parking canopies to support solar panels to produce 600 MWh electricity, Tice Creek Road, Walnut Creek, California.</li>
              <li>Inground Solar Panel Canopies Construction, in Woodland, CA</li>
              <li>Convenience Stores Expansion construction Barrett Avenue, in Richmond, California.</li>
              <li>Approximately 5 acres business center development, OLD Highway 53, Clearlake, California.</li>
              <li>Four story mix-use building construction P Street, in Sacramento, California.</li>
              <li>New restaurant construction, 24th Street, in San Jose, California.</li>
              <li>Boat Storage Facility, Lindemann Road, in Byron, California.</li>
              <li>New three-story apartment building construction, Shaver Street, San Rafael, California.</li>
              <li>Approximately 18,000 square feet mix-use building construction, Moss Beach, California.</li>
              <li>Several equipment pads construction, Crown Bolt Warehouse, Stockton, California.</li>
              <li>Three-story high restaurant construction, 76th Street, Oakland, California.</li>
              <li>Driving range renovation project, Chabot Golf Course, Oakland, California.</li>
              <li>William Plaza Construction, approximately 18,600 square feet commercial building construction, San Jose, California.</li>
              <li>Studio Office Building construction, Davis, California.</li>
              <li>At & T transmission Pole’s construction, Tracy, California.</li>
            </ul>
          </div>

        </div>
      </div>
    </div><!-- row -->
  </div><!-- container -->
</div><!-- details section -->

<app-footer></app-footer>
