<app-client-section></app-client-section>
<footer class="footer">
  <div class="contact-section">
    <div class="container">
      <div class="row">

        <div class="col-sm-3">
          <div class="contact-widget">
            <img class="img-responsive" src="images/logo2.png" alt="">
            <div class="contact-widget">
              <ul>
                <li><a href="/home">Home</a></li>
                <li><a href="/about-us">About Us</a></li>
                <li><a href="/services">Services</a></li>
              </ul>
              <ul>
                <li><a href="/projects">Projects</a></li>
                <li><a href="/careers">Careers</a></li>
                <li><a href="/contact-us">Contact Us</a></li>
              </ul>
            </div>
          </div>
        </div>

        <div class="col-sm-9">
          <div class="contact-widget">
            <h3 class="text-center custom-footer">Our Offices</h3>
            <div class="col-sm-4 custom-padding">
              <address>
                <ul>
                  <li>11501 Dublin Blvd, Suite 200,</li>
                  <li>Dublin</li>
                  <li>CA 94568</li>
                  <li><span>Phone: </span><a href="tel:+15105013240">(510) 501-3240</a></li>
                </ul>
              </address>
            </div>
            <div class="col-sm-4 custom-padding">
              <address>
                <ul>
                  <li>1869 Park Oak Drive,</li>
                  <li>Roseville</li>
                  <li>CA 95661</li>
                  <li><span>Phone: </span><a href="tel:+19162428286">(916) 242-8286</a></li>
                </ul>
              </address>
            </div>
            <div class="col-sm-4 custom-padding">
              <address>
                <ul>
                  <li>8 Whatney, Unit 100,</li>
                  <li>Irvine</li>
                  <li>CA 92618</li>
                  <li><span>Phone: </span><a href="tel:+19492073325">(949) 207-3325</a></li>
                </ul>
              </address>
            </div>
          </div>
        </div>

      </div><!-- row -->

    </div><!-- container -->
  </div><!-- contact informetion -->

  <div class="footer-bottom">
    <div class="container">
      <div class="copyright-text text-center">
        <p>Copyright © 2021 Visha Consultants.</p>
      </div>
    </div>
  </div><!-- footer bottom -->
</footer><!-- footer -->
