import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-single-home',
  templateUrl: './single-home.component.html',
  styleUrls: ['./single-home.component.css']
})
export class SingleHomeComponent implements OnInit {

  constructor() { }
  isShow = false;

  ngOnInit(): void {
  }

}
